import { DemandType, Type } from "../enums/type";
import { Grid, Grow, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import DemandCard from "./demand-card";
import MovieService from "../services/movie-service";
import SearchBar from "../components/search-bar";
import TvShowService from "../services/tv-show-service";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(4)
    }
}));

export default function DemandSearch({ type }: { type: DemandType }) {
    const classes = useStyles();
    const [demandData, setDemandData] = useState([]);
    const searchItem = useCallback(
        searchTerm => {
            switch (type) {
                case Type.MOVIE:
                    MovieService.search(searchTerm)
                        .then(res => res.json())
                        .then(json => handleResponse(json));
                    break;
                case Type.TV_SHOW:
                    TvShowService.search(searchTerm)
                        .then(res => res.json())
                        .then(json => handleResponse(json));
                    break;
            }
        },
        [type]
    );
    const handleResponse = (json: any) => {
        const results = json.results;
        results.sort((a: any, b: any) => (a.popularity < b.popularity ? 1 : -1));
        setDemandData(results);
    };
    const getComponentTitle = () => {
        switch (type) {
            case Type.MOVIE:
                return "Rechercher un film";
            case Type.TV_SHOW:
                return "Rechercher une série";
        }
    };
    return (
        <Grow in={true}>
            <div>
                <Typography variant="h5" component="h2">
                    {getComponentTitle()}
                </Typography>
                <SearchBar onSearch={searchTerm => searchItem(searchTerm)} onSearchCancel={() => setDemandData([])} />
                <Grid container spacing={2} className={classes.cardGrid}>
                    {demandData.map((demand: any) => (
                        <DemandCard key={demand.id} demand={demand} type={type} />
                    ))}
                </Grid>
            </div>
        </Grow>
    );
}
