import React, { useCallback } from "react";

import { Button, Container, TextField, Snackbar, Grow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import AuthService from "../services/auth-service";

import { useHistory } from "react-router-dom";

import Logo from "./logo.png";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: "white",
        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
        padding: "0 3% 1% 3%"
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    logo: {
        margin: theme.spacing(1),
        marginTop: "5%"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function Login() {
    const classes = useStyles();
    const history = useHistory();
    const [email, setEmail] = React.useState("");
    const [isEmailError, setEmailError] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const [isPasswordError, setPasswordError] = React.useState(false);
    const [isToastOpen, setToastOpen] = React.useState(false);
    const [isButtonDisabled, setButtonDisabled] = React.useState(false);

    const signIn = useCallback(() => {
        setButtonDisabled(true);
        AuthService.login(email, password)
            .then(res => {
                if (res.ok) {
                    AuthService.setAuthentication();
                    history.push("/");
                } else {
                    setButtonDisabled(false);
                    setToastOpen(true);
                }
            })
            .catch(() => {
                setButtonDisabled(false);
                setToastOpen(true);
            });
    }, [history, email, password]);

    const handleFormValidation = () => {
        let isFormValid = true;
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setEmailError(true);
            isFormValid = false;
        }
        if (password.length < 4) {
            setPasswordError(true);
            isFormValid = false;
        }
        if (isFormValid) {
            signIn();
        }
    };

    return (
        <Grow in={true}>
            <Container component="main" maxWidth="xs" className={classes.container}>
                <div className={classes.paper}>
                    <img src={Logo} alt="NeedForFlix" className={classes.logo} />
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                            error={isEmailError}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                            error={isPasswordError}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isButtonDisabled}
                            onClick={event => {
                                event.preventDefault();
                                handleFormValidation();
                            }}
                        >
                            Connexion
                        </Button>
                    </form>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={isToastOpen}
                    autoHideDuration={5000}
                    onClose={() => setToastOpen(false)}
                    message="Email ou mot de passe incorrect"
                />
            </Container>
        </Grow>
    );
}
