import { Redirect, Route, RouteProps } from "react-router-dom";

import AppContainer from "./app-container";
import AuthService from "../services/auth-service";
import React from "react";

interface PrivateRouteProps extends RouteProps {
    children: React.ReactNode;
}

interface PrivateRouteState {
    isAuth: boolean;
    isLoading: boolean;
}

export default class PrivateRoute extends React.Component<PrivateRouteProps, PrivateRouteState> {
    constructor(props: PrivateRouteProps) {
        super(props);
        this.state = { isAuth: false, isLoading: true };
    }
    componentDidMount() {
        AuthService.isAuthenticated().then(isAuth => {
            this.setState({ isAuth, isLoading: false });
        });
    }
    render() {
        const { children } = this.props;
        const { isAuth, isLoading } = this.state;
        return (
            !isLoading && (
                <Route
                    render={({ location }) =>
                        isAuth ? (
                            <AppContainer>{children}</AppContainer>
                        ) : (
                            <Redirect to={{ pathname: "/login", state: { from: location } }} />
                        )
                    }
                />
            )
        );
    }
}
