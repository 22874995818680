import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Button, IconButton, Popover } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import AuthService from "../services/auth-service";
import UserService from "../services/user-service";

import { User } from "../types/user";

import "./user-badge.scss";

const useStyles = makeStyles(theme => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

export default function UserBadge() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [user, setUser] = React.useState<User>(new User());
    const history = useHistory();

    const handleDisconnect = () => {
        AuthService.logout();
        history.push("/login");
    };

    React.useEffect(() => {
        UserService.getUserInfo()
            .then(res => res.json())
            .then(response => setUser(response));
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div className="userBadge">
            <IconButton aria-describedby={id} onClick={handleClick}>
                <Avatar className={classes.small} src={user.picture} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <div className="userDialogContainer">
                    <Avatar className={classes.large} src={user.picture} />
                    <p>
                        <b>{user.username}</b>
                    </p>
                    <p>{user.email}</p>
                    <Button id="disconnectButton" variant="outlined" onClick={handleDisconnect}>
                        Déconnexion
                    </Button>
                </div>
            </Popover>
        </div>
    );
}
