export class TMDbItem {
    id = 0
    title = "" // Movie
    name = "" // TvShow
    poster_path = ""
    overview = ""
    release_date = "" // Movie
    first_air_date = "" // TvShow
    genres: Array<{ name: string }> = []
    credits: Credits = new Credits()
    number_of_seasons = 0
}

class Credits {
    cast: Array<TMDbCast> = []
    crew: Array<TMDbCast> = []
}

class TMDbCast {
    id = 0
    cast_id = 0
    name = "" // Nom de l'acteur
    character = "" // Nom du personage
    profile_path = ""
}
