import React, { Fragment, useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import { Box, Grow, makeStyles, Slide, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";

import Autocomplete from "@material-ui/lab/Autocomplete";

import MusicService from "../services/music-service";

import { Artist } from "../types/artist";
import { Album } from "../types/album";
import AlbumList from "./album-list";

const useStyles = makeStyles(theme => ({
    smallPoster: {
        width: "3em",
        marginRight: "1em"
    },
    bigPosterContainer: {
        textAlign: "center",
        marginLeft: "5em",
        marginTop: "1em"
    },
    bigPoster: {
        width: "25em"
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    title: {
        marginBottom: "1em"
    }
}));

export default function DemandMusic() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [artists, setArtists] = useState<Artist[]>([]);
    const [artist, setArtist] = useState<Artist>(new Artist());
    const [queryArtist, setQueryArtist] = useState("");
    const [albums, setAlbums] = useState<Album[]>([]);

    useEffect(() => {
        if (!queryArtist) {
            setArtists([]);
            return undefined;
        }
        setLoading(true);
        (async () => {
            const response = await MusicService.searchArtist(queryArtist);
            const jsonResponse = await response.json();
            const dataList = jsonResponse.data;
            setLoading(false);
            setArtists(dataList);
        })();
    }, [queryArtist]);

    const onArtistChange = (artist: Artist) => {
        setArtist(artist);
        if (artist.id) {
            MusicService.getDiscography(artist.id)
                .then(res => res.json())
                .then(json => setAlbums(json.data));
        } else {
            setAlbums([]);
        }
    };

    return (
        <Grow in={true}>
            <Box display="flex">
                <div>
                    <Typography variant="h5" component="h2" className={classes.title}>
                        Nouvelle demande de musique
                    </Typography>
                    <Autocomplete
                        id="autocomplete-search-artist"
                        freeSolo
                        style={{ width: 1000, marginBottom: "1em" }}
                        getOptionSelected={(artist, value) => artist.name === value.name}
                        getOptionLabel={artist => artist.name}
                        options={artists}
                        loading={loading}
                        inputValue={queryArtist}
                        onInputChange={(_event, value) => setQueryArtist(value)}
                        onChange={(_event, value) => onArtistChange((value as Artist) || {})}
                        renderOption={option => (
                            <Fragment>
                                <img src={option.picture} alt="option.name" className={classes.smallPoster}></img>
                                {option.name}
                            </Fragment>
                        )}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Rechercher un artiste"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                    ),
                                    endAdornment: (
                                        <Fragment>
                                            {loading && <CircularProgress color="inherit" size={20} />}
                                            {params.InputProps.endAdornment}
                                        </Fragment>
                                    )
                                }}
                            />
                        )}
                    />
                    <AlbumList albums={albums}></AlbumList>
                </div>
                {artist.name && (
                    <Slide direction="left" in={true}>
                        <div className={classes.bigPosterContainer}>
                            <h2>{artist.name}</h2>
                            <img src={artist.picture_xl} alt={artist.name} className={classes.bigPoster}></img>
                        </div>
                    </Slide>
                )}
            </Box>
        </Grow>
    );
}
