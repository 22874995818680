import { Box, IconButton, InputBase } from "@material-ui/core";
import React, { useState } from "react";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

type SearchBarProps = {
    onSearchCancel(): void;
    onSearch: (callback: string) => void;
};

const useStyles = makeStyles(theme => ({
    search: {
        marginTop: "1em",
        display: "flex",
        justifyContent: "center",
        maxWidth: "100%",
        transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.shortest
        }),
        height: "3rem"
    },
    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        flex: 1,
        alignItems: "center"
    },
    inputInput: {
        width: "100%"
    }
}));

export default function SearchBar(props: SearchBarProps) {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    const onSearchCancel = () => {
        setSearchTerm("");
        props.onSearchCancel();
    };
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            if (searchTerm) {
                props.onSearch(searchTerm);
            } else {
                props.onSearchCancel();
            }
        }
    };

    const onChange = (value: string) => {
        if (value.length > 1) {
            props.onSearch(value);
        } else if (value.length === 0) {
            props.onSearchCancel();
        }
        setSearchTerm(value);
    };

    return (
        <Box className={classes.search} boxShadow={2}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder="Recherche"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                }}
                value={searchTerm}
                inputProps={{ "aria-label": "search" }}
                onChange={event => onChange(event.target.value)}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(event)}
            />
            {searchTerm.length > 0 && (
                <IconButton onClick={onSearchCancel}>
                    <CloseOutlinedIcon />
                </IconButton>
            )}
        </Box>
    );
}
