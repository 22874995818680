import NetworkService from "./network-service";

const URL = "/music";

const MusicService = {
    searchArtist: (searchTerm) => NetworkService.doGetRequest(`${URL}/searchArtist?searchTerm=${encodeURIComponent(searchTerm)}`),
    getDiscography: (id) => NetworkService.doGetRequest(`${URL}/getDiscography?id=${id}`),
    getAlbumDetails: (id) => NetworkService.doGetRequest(`${URL}/albums?id=${id}`),
};

export default MusicService;