import NetworkService from "./network-service";

const URL = "/demands";

const DemandService = {
    getDemands: () => NetworkService.doGetRequest(`${URL}/`),
    getDemandsAdmin: () => NetworkService.doGetRequest(`${URL}/admin`),
    getDemandsArchives: () => NetworkService.doGetRequest(`${URL}/archives`),
    updateDemand: (demand) => NetworkService.doPutRequestJson(`${URL}/`, demand),
    createDemand: (demand) => NetworkService.doPostRequestJson(`${URL}/`, demand),
};

export default DemandService;