import React from "react";

import { GridList, GridListTile, GridListTileBar, Zoom } from "@material-ui/core";

import { Album } from "../types/album";
import { useHistory } from "react-router-dom";

type AlbumListProps = {
    albums: Album[];
};

export default function AlbumList(props: AlbumListProps) {
    const { albums } = props;
    const history = useHistory();
    return (
        <GridList cellHeight={250} cols={4} spacing={12}>
            {albums.map(album => (
                <Zoom key={album.id} in={true}>
                    <GridListTile key={album.id} style={{ cursor: "pointer" }}>
                        <img
                            src={album.cover_xl}
                            alt={album.title}
                            onClick={() => history.push(`/newDemand/music/${album.id}`)}
                        />
                        <GridListTileBar title={album.title} subtitle={album.release_date.substring(0, 4)} />
                    </GridListTile>
                </Zoom>
            ))}
        </GridList>
    );
}
