import AppBarDrawer from "../app-bar-drawer";
import { Container } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    }
}));

type AppContainerProps = {
    children: React.ReactNode;
};

export default function AppContainer(props: AppContainerProps) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBarDrawer />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    {props.children ?? ""}
                </Container>
            </main>
        </div>
    );
}
