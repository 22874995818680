import { config } from "../env";

const NetworkService = {
    doGetRequest: resource => {
        return fetch(`${config.url}${resource}`);
    },
    doPostRequest: (resource, body, headers) => {
        return fetch(`${config.url}${resource}`, {
            method: "POST",
            headers,
            body
        });
    },
    doPostRequestJson: (resource, body) => {
        return fetch(`${config.url}${resource}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
    },
    doPutRequestJson: (resource, body) => {
        return fetch(`${config.url}${resource}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
    }
};

export default NetworkService;
