import React from 'react';

import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';

import MovieIcon from '@material-ui/icons/Movie';
import TvIcon from '@material-ui/icons/Tv';
import MusicNoteIcon from '@material-ui/icons/MusicNote';

import { useHistory } from 'react-router-dom';

import './index.scss'

const actions = [
    { icon: <MusicNoteIcon />, name: 'Musique', path: 'music' },
    { icon: <TvIcon />, name: 'Série Tv', path: 'tvShow' },
    { icon: <MovieIcon />, name: 'Film', path: 'movie' }
];

export default function FloatingActionButton() {
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const handleMiniDialClick = (path: string) => {
        history.push(`/newDemand/${path}`);
    }
    return (
        <SpeedDial
            ariaLabel="SpeedDial"
            className={"floating-action-button"}
            icon={<SpeedDialIcon />}
            onClose={() => setOpen(false)}
            onClick={() => setOpen(!open)}
            open={open}
        >
            {actions.map(action =>
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => handleMiniDialClick(action.path)}
                />
            )}
        </SpeedDial>
    );
}