import NetworkService from "./network-service";

const URL = "/movies";

const MovieService = {
    search: searchTerm => NetworkService.doGetRequest(`${URL}/search?searchTerm=${encodeURIComponent(searchTerm)}`),
    getMovie: id => NetworkService.doGetRequest(`${URL}/${id}`)
};

export default MovieService;
