import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh'
    }
});

export default function Loader() {
    const classes = useStyles();
    return (
        <div className={classes.loader}>
            <CircularProgress />
        </div>
    )
}
