import React from 'react';

import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Router from './router'

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#0070E0'
		},
		secondary: {
			main: '#e05600'
		}
	}
});

export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router />
		</ThemeProvider>
	);
}
