import { Assignment, BugReport, Folder } from "@material-ui/icons";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import { Link } from "react-router-dom";
import React from "react";
import { User } from "../types/user";
import UserService from "../services/user-service";

export default function Menu() {
    const [isAdmin, setIsAdmin] = React.useState(false);

    React.useEffect(() => {
        UserService.getUserInfo()
            .then(res => res.json())
            .then((response: User) => setIsAdmin(response.username === "etienne582"));
    }, []);

    return (
        <List>
            <ListItem button component={Link} to="/">
                <ListItemIcon>
                    <Assignment />
                </ListItemIcon>
                <ListItemText primary="Mes demandes" />
            </ListItem>
            {isAdmin && (
                <ListItem button component={Link} to="/admin">
                    <ListItemIcon>
                        <BugReport />
                    </ListItemIcon>
                    <ListItemText primary="Toutes les demandes" />
                </ListItem>
            )}
            <ListItem button component={Link} to="/archives">
                <ListItemIcon>
                    <Folder />
                </ListItemIcon>
                <ListItemText primary="Archives" />
            </ListItem>
            {/*             
            <ListItem button component={Link} to="/settings">
                <ListItemIcon>
                    <Settings />
                </ListItemIcon>
                <ListItemText primary="Paramètres" />
            </ListItem> 
            */}
        </List>
    );
}
