import React from 'react';
import { IconButton, Popover, Badge } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

export default function UserNotifications() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className='userNotifications'>
            <IconButton color='inherit' aria-describedby={id} onClick={handleClick}>
                <Badge badgeContent={0} color='secondary'>{/* TODO: badgeContent : nombre de notification */}
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className='userDialogContainer'>{/* Gestion du style dédié au notifs*/}
                    <p>Aucune notification</p>
                </div>
            </Popover>
        </div>
    );
}