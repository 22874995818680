import NetworkService from './network-service';

const AuthService = {
    isAuthenticated: async () => {
        if (sessionStorage.getItem('isAuth')) {
            return true;
        }
        const isAuth = await NetworkService.doGetRequest("/users/isAuth");
        if (isAuth.ok) {
            AuthService.setAuthentication();
        }
        return isAuth.ok;
    },
    setAuthentication: () => {
        sessionStorage.setItem('isAuth', 'true');
    },
    login: (email, password) => {
        return NetworkService.doPostRequest(
            "/login",
            `email=${email}&password=${password}`,
            new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' })
        );
    },
    logout: () => {
        NetworkService.doGetRequest("/users/deconnection");
        sessionStorage.clear();
    }
};

export default AuthService;
