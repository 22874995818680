import React from 'react';

import { HashRouter, Switch, Route } from "react-router-dom";

import PrivateRoute from "./private-route"

import DemandList from '../demand-list'
import DemandSearch from '../demand-search';
import DemandCreate from '../demand-create'

import Login from '../login'

import { Type } from '../enums/type';
import DemandMusic from '../demand-music';

export default function Router() {
    return (
        <HashRouter>
            <Switch>
                <Route path="/login"><Login /></Route>
                <PrivateRoute path="/admin"><DemandList route={"/admin"} /></PrivateRoute>
                <PrivateRoute path="/archives"><DemandList route={"/archives"} /></PrivateRoute>
                <PrivateRoute path="/settings">Click pas ici wesh</PrivateRoute>
                <PrivateRoute path="/newDemand/movie/:id"><DemandCreate type={Type.MOVIE} /></PrivateRoute>
                <PrivateRoute path="/newDemand/movie"><DemandSearch type={Type.MOVIE} /></PrivateRoute>
                <PrivateRoute path="/newDemand/tvShow/:id"><DemandCreate type={Type.TV_SHOW} /></PrivateRoute>
                <PrivateRoute path="/newDemand/tvShow"><DemandSearch type={Type.TV_SHOW} /></PrivateRoute>
                <PrivateRoute path="/newDemand/music/:id"><DemandCreate type={Type.MUSIC} /></PrivateRoute>
                <PrivateRoute path="/newDemand/music"><DemandMusic /></PrivateRoute>
                <PrivateRoute path="/"><DemandList route={"/"} /></PrivateRoute>
            </Switch>
        </HashRouter>
    );
}
