import { Type } from "../enums/type";
import { Demand } from "./demand";

const TMDB_URL = "https://image.tmdb.org/t/p/w780/";

export class DemandGeneric {
    private type: string;
    private demand: Demand;

    constructor(demand: Demand) {
        this.demand = demand;
        // Set Demand Type in constructor
        if (this.demand.demandMovie) {
            this.type = Type.MOVIE;
        } else if (this.demand.demandTvShow) {
            this.type = Type.TV_SHOW;
        } else if (this.demand.demandMusic) {
            this.type = Type.MUSIC;
        } else {
            this.type = "ERROR";
        }
    }

    getType() {
        return this.type;
    }

    getTitle() {
        switch (this.type) {
            case Type.MOVIE:
                return this.demand.demandMovie.title;
            case Type.TV_SHOW:
                return this.demand.demandTvShow.title;
            case Type.MUSIC:
                return this.demand.demandMusic.title;
        }
    }

    getPicture() {
        switch (this.type) {
            case Type.MOVIE:
                return TMDB_URL + this.demand.demandMovie.poster;
            case Type.TV_SHOW:
                return TMDB_URL + this.demand.demandTvShow.poster;
            case Type.MUSIC:
                return this.demand.demandMusic.poster;
        }
    }

    getYear() {
        switch (this.type) {
            case Type.MOVIE:
                return this.demand.demandMovie.year;
            case Type.TV_SHOW:
                return this.demand.demandTvShow.year;
            case Type.MUSIC:
                return this.demand.demandMusic.year;
        }
    }
    getGenres() {
        let genres = "";
        switch (this.type) {
            case Type.MOVIE:
                genres = this.demand.demandMovie.genres;
                break;
            case Type.TV_SHOW:
                genres = this.demand.demandTvShow.genres;
                break;
        }
        if (this.type !== Type.MUSIC) {
            return JSON.parse(genres)
                .map((g: { name: string }) => g.name)
                .join(", ");
        }
    }
    getSynopsis() {
        switch (this.type) {
            case Type.MOVIE:
                return this.demand.demandMovie.synopsis;
            case Type.TV_SHOW:
                return this.demand.demandTvShow.synopsis;
        }
    }
    getLanguage() {
        switch (this.type) {
            case Type.MOVIE:
                return this.demand.demandMovie.language;
            case Type.TV_SHOW:
                return this.demand.demandTvShow.language;
        }
    }

    getSeason() {
        return this.type === Type.TV_SHOW && this.demand.demandTvShow.season;
    }

    getArtist() {
        return this.type === Type.MUSIC && this.demand.demandMusic.artist;
    }
}
