import { DemandType, Type } from "../enums/type";
import React, { useState } from "react";

import { Demand } from "../types/demand";
import DemandCreateDeezer from "./demand-create-deezer";
import DemandCreateTmdb from "./demand-create-tmdb";
import DemandService from "../services/demand-service";
import { Snackbar } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function DemandCreate({ type }: { type: DemandType }) {
    const history = useHistory();

    const [isToastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const displayCreateComponent = () => {
        switch (type) {
            case Type.MUSIC:
                return <DemandCreateDeezer createDemand={createDemand} />;
            case Type.MOVIE:
            case Type.TV_SHOW:
            default:
                return <DemandCreateTmdb type={type} createDemand={createDemand} />;
        }
    };
    const createDemand = (newDemand: Demand) => {
        DemandService.createDemand(newDemand).then(res => {
            if (res.ok) {
                setToastMessage("La demande a été créée avec succès");
                setTimeout(() => history.push("/"), 1000);
            } else {
                setToastMessage("Problème durant la création de la demande");
            }
            setToastOpen(true);
        });
    };
    return (
        <div>
            {displayCreateComponent()}
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={isToastOpen}
                autoHideDuration={3000}
                onClose={() => setToastOpen(false)}
                message={toastMessage}
            />
        </div>
    );
}
