import { Artist } from "."

export class DeezerItem {
    id = 0
    artist = new Artist()
    cover_xl = ""
    release_date = ""
    title = ""
    tracks = {
        data: [
            { title: "" }]
    }
}