import React from "react";

import { Card, CardActionArea, CardContent, CardMedia, Grid, Zoom, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Type } from "../enums/type";

type DemandCardProps = {
    demand: any;
    type: string;
};

const useStyles = makeStyles(() => ({
    card: {
        display: "flex",
        height: "15em"
    },
    cardDetails: {
        flex: 1
    },
    cardMedia: {
        width: 150
    },
    cardContent: {
        flexGrow: 1
    }
}));

export default function DemandCard(props: DemandCardProps) {
    const { demand, type } = props;
    const classes = useStyles();
    const { poster_path, id } = demand;
    const posterUrl = poster_path
        ? `https://image.tmdb.org/t/p/w342${demand.poster_path}`
        : "https://amo-aura.fr/web/img/happenings/no-picture.png";
    const getRedirectionLink = () => {
        switch (type) {
            case Type.MOVIE:
                return `#/newDemand/movie/${id}`;
            case Type.TV_SHOW:
                return `#/newDemand/tvShow/${id}`;
        }
    };
    const displayTitle = () => {
        return type === Type.MOVIE ? demand.title : demand.name;
    };
    return (
        <Zoom in={true}>
            <Grid item xs={12} sm={6} md={4}>
                <CardActionArea component="a" href={getRedirectionLink()}>
                    <Card className={classes.card}>
                        <CardMedia className={classes.cardMedia} image={posterUrl} />
                        <div className={classes.cardDetails}>
                            <CardContent>
                                <Typography component="h2" variant="h6">
                                    {displayTitle()}
                                </Typography>
                                <Typography variant="subtitle1" paragraph>
                                    {demand.overview}
                                </Typography>
                            </CardContent>
                        </div>
                    </Card>
                </CardActionArea>
            </Grid>
        </Zoom>
    );
}
