import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Divider, Drawer, IconButton, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';

import { isMobile } from "react-device-detect";

import Menu from './menu'
import UserBadge from './user-badge'
import UserNotifications from './user-notifications'

import Logo from './logo.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: theme.spacing(28),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(24),
            marginLeft: '-2em'
        },
    },
    logoHiddenMobile: {
        width: theme.spacing(24),
        marginLeft: 0
    },
    drawerPaper: {
        height: '100vh',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        height: '100vh',
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(0),
        },
    },
    secondaryColor: {
        backgroundColor: theme.palette.secondary.main //Non utilisé pour le moment
    }
}));

export default function AppBarDrawer() {
    const classes = useStyles();
    const [isDrawerOpen, setDrawerOpen] = React.useState(false); // TODO : false > !isMobile ou gestion via des settings utilisateurs
    return (
        <div className='appBarDrawer'>
            <AppBar position="absolute" className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(true)}
                        className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div className={classes.title}>
                        <a href="/">
                            <img src={Logo} alt='NeedForFlix' className={clsx(classes.logo, isDrawerOpen && isMobile && classes.logoHiddenMobile)} />
                        </a>
                    </div>
                    <UserNotifications />
                    <UserBadge />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{ paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose) }}
                open={isDrawerOpen}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={() => setDrawerOpen(false)}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <Menu />
            </Drawer>
        </div>
    );
}