import React, { useCallback, useEffect, useState } from "react";

import {
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Card,
    CardMedia,
    CardContent,
    Hidden,
    Paper,
    GridList,
    GridListTile,
    GridListTileBar,
    Grow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MovieService from "../services/movie-service";
import TvShowService from "../services/tv-show-service";
import UserService from "../services/user-service";

import Loader from "../components/loader";

import { TMDbItem } from "../types/tmdb-item";
import { User } from "./../types/user";
import { Type } from "../enums/type";

const useStyles = makeStyles(() => ({
    card: {
        display: "flex",
        marginBottom: "3vh",
        minHeight: "35em"
    },
    cardDetails: {
        flex: 1
    },
    cardMedia: {
        width: 320
    },
    synopsis: {
        marginTop: "0.5em",
        marginBottom: "0.1em"
    },
    paper: {
        display: "flex",
        alignItems: "center",
        padding: "1em"
    },
    buttonRight: {
        marginLeft: "auto"
    },
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)" // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    },
    titleBar: {
        background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
    },
    title: {
        textAlign: "center",
        paddingTop: "1.5em",
        fontSize: "small"
    },
    formControl: {
        marginRight: "1em"
    }
}));

const getIdFromUrl = () => {
    const url = window.location.href;
    return url.substring(url.lastIndexOf("/") + 1);
};

export default function DemandCreateTmdb({ type, createDemand }: any) {
    const classes = useStyles();

    const [tmdbItem, setTmdbItem] = useState<TMDbItem>(new TMDbItem());
    const [language, setLanguage] = useState(0);
    const [season, setSeason] = useState(1);
    const [userId, setUserId] = useState(0);
    const [userList, setUserList] = useState<User[]>([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        if (type === Type.MOVIE) {
            MovieService.getMovie(getIdFromUrl())
                .then(res => res.json())
                .then(json => storeTmdbItem(json));
        } else {
            TvShowService.getTvShow(getIdFromUrl())
                .then(res => res.json())
                .then(json => storeTmdbItem(json));
        }
        UserService.getAllUsers()
            .then(res => res.json())
            .then(json => {
                const defaultUser = json.find((user: User) => user.username === "etienne582");
                setUserId(defaultUser.id);
                setUserList(json);
            });
    }, [type]);
    const handleCreateDemand = useCallback(() => {
        setButtonDisabled(true);
        const user = userList.find((user: User) => user.id === userId);
        let newDemandMovie = undefined;
        let newDemandTvShow = undefined;
        if (type === Type.MOVIE) {
            newDemandMovie = {
                tmdbId: tmdbItem.id,
                title: tmdbItem.title,
                poster: tmdbItem.poster_path,
                year: tmdbItem.release_date.substring(0, 4),
                genres: JSON.stringify(tmdbItem.genres),
                synopsis: tmdbItem.overview,
                language
            };
        } else {
            newDemandTvShow = {
                tmdbId: tmdbItem.id,
                title: tmdbItem.name,
                poster: tmdbItem.poster_path,
                year: tmdbItem.first_air_date.substring(0, 4),
                genres: JSON.stringify(tmdbItem.genres),
                synopsis: tmdbItem.overview,
                language,
                season
            };
        }
        const newDemand = {
            demandMovie: newDemandMovie,
            demandTvShow: newDemandTvShow,
            userDest: user
        };
        createDemand(newDemand);
    }, [tmdbItem, language, userList, userId, season, type, createDemand]);
    const storeTmdbItem = (json: any) => {
        json.credits.cast = json.credits.cast.slice(0, 7); // On affiche les 1er acteurs
        json.credits.crew = []; // Pour ne pas saturer le state
        setTmdbItem(json);
    };
    const displayDateAndGenres = () => {
        const date =
            type === Type.MOVIE ? tmdbItem.release_date.substring(0, 4) : tmdbItem.first_air_date.substring(0, 4);
        const genres = tmdbItem.genres.map(g => g.name).join(", ");
        return `${date} • ${genres}`;
    };
    const displayTitle = () => {
        return type === Type.MOVIE ? tmdbItem.title : tmdbItem.name;
    };
    const displaySeasons = () => {
        const items = [];
        for (let indexSeason = 1; indexSeason <= tmdbItem.number_of_seasons; indexSeason++) {
            items.push(<MenuItem value={indexSeason}>Saison {indexSeason}</MenuItem>);
        }
        return items;
    };

    return tmdbItem.title === "" ? (
        <Loader />
    ) : (
        <Grow in={true}>
            <div>
                <Card className={classes.card}>
                    <Hidden xsDown>
                        <CardMedia
                            className={classes.cardMedia}
                            image={`https://image.tmdb.org/t/p/w780/${tmdbItem.poster_path}`}
                        />
                    </Hidden>
                    <div className={classes.cardDetails}>
                        <CardContent>
                            <Typography component="h1" variant="h4">
                                {displayTitle()}
                            </Typography>
                            <Typography color="textSecondary">{displayDateAndGenres()}</Typography>
                            <Typography component="h2" variant="h6" className={classes.synopsis}>
                                {"Synopsis"}
                            </Typography>
                            <Typography variant="subtitle1" paragraph align="justify">
                                {tmdbItem.overview}
                            </Typography>
                            <Typography component="h2" variant="h6">
                                {"Casting"}
                            </Typography>
                            <GridList className={classes.gridList} cols={7}>
                                {tmdbItem.credits.cast.map(
                                    cast =>
                                        cast.profile_path && (
                                            <GridListTile key={"title" + cast.id}>
                                                <img
                                                    src={`https://image.tmdb.org/t/p/w185/${cast.profile_path}`}
                                                    alt={cast.name}
                                                />
                                                <GridListTileBar
                                                    key={"titlebar" + cast.id}
                                                    title={cast.name}
                                                    classes={{
                                                        root: classes.titleBar,
                                                        title: classes.title
                                                    }}
                                                />
                                            </GridListTile>
                                        )
                                )}
                            </GridList>
                        </CardContent>
                    </div>
                </Card>
                <Paper className={classes.paper}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="dest-select-label">Destinataire</InputLabel>
                        <Select
                            labelId="dest-select-label"
                            id="dest-select"
                            value={userId}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                setUserId(event.target.value as number)
                            }
                            label="Destinataire"
                        >
                            {userList.map((user: User) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user.username}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="language-select-label">Langue</InputLabel>
                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            value={language}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                setLanguage(event.target.value as number)
                            }
                            label="Langue"
                        >
                            <MenuItem value={0}>Version française</MenuItem>
                            <MenuItem value={1}>Version Originale</MenuItem>
                        </Select>
                    </FormControl>
                    {type === Type.TV_SHOW && (
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="season-select-label">Saison</InputLabel>
                            <Select
                                labelId="season-select-label"
                                id="season-select"
                                value={season}
                                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                    setSeason(event.target.value as number)
                                }
                                label="Saison"
                            >
                                {displaySeasons()}
                            </Select>
                        </FormControl>
                    )}
                    <div className={classes.buttonRight}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleCreateDemand}
                            disabled={isButtonDisabled}
                        >
                            Valider
                        </Button>
                    </div>
                </Paper>
            </div>
        </Grow>
    );
}
