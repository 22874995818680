import React, { useEffect, useState } from "react";

import DemandLine from "./demand-line";
import FlotingActionButton from "../components/floating-action-button";
import DemandService from "../services/demand-service";

import Loader from "../components/loader";

import { Demand } from "./../types/demand";

type DemandListProps = {
    route: string;
};

export default function DemandList({ route }: DemandListProps) {
    const [demandList, setDemandList] = useState<Demand[]>();
    useEffect(() => {
        switch (route) {
            case "/admin":
                DemandService.getDemandsAdmin()
                    .then(res => res.json())
                    .then(json => setDemandList(json));
                break;
            case "/archives":
                DemandService.getDemandsArchives()
                    .then(res => res.json())
                    .then(json => setDemandList(json));
                break;
            case "/":
            default:
                DemandService.getDemands()
                    .then(res => res.json())
                    .then(json => setDemandList(json));
        }
    }, [route]);
    return !demandList ? (
        <Loader />
    ) : (
        <div>
            {demandList.map(demand => (
                <DemandLine key={demand.id} demand={demand} />
            ))}
            <FlotingActionButton />
        </div>
    );
}
