import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
    Card,
    CardContent,
    CardMedia,
    FormControl,
    Hidden,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Typography,
    Snackbar,
    Fade
} from "@material-ui/core";

import DemandService from "../services/demand-service";

import { Demand, DemandGeneric } from "../types";
import { Status, Type } from "../enums";

type DemandLineProps = {
    demand: Demand;
};

const useStyles = makeStyles({
    card: {
        display: "flex",
        marginBottom: "3vh",
        minHeight: "14em"
    },
    cardDetails: {
        flex: 1
    },
    cardMedia: {
        width: "14em"
    },
    statusContainer: {
        marginTop: 30,
        display: "flex"
    },
    status: {
        marginLeft: "auto",
        minWidth: 140
    },
    synopsis: {
        marginTop: "0.5em",
        marginBottom: "0.1em"
    },
    readOnlyInput: {
        maxWidth: 130,
        marginRight: "1em"
    },
    authorInput: {
        maxWidth: 100,
        marginRight: "1em"
    }
});

export default function DemandLine({ demand }: DemandLineProps) {
    const classes = useStyles();

    const [demandStatus, setDemandStatus] = useState<string>(demand.status);
    const [isToastOpen, setToastOpen] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState("");

    const handleStatusChange = (newStatus: string) => {
        demand.status = newStatus;
        setDemandStatus(newStatus);
        DemandService.updateDemand(demand).then(() => {
            setToastMessage("Le statut de la demande a été mis à jour");
            setToastOpen(true);
        });
    };

    const demandGeneric = new DemandGeneric(demand);

    return (
        <Fade in={true}>
            <Card className={classes.card}>
                <Hidden xsDown>
                    <CardMedia className={classes.cardMedia} image={demandGeneric.getPicture()} />
                </Hidden>
                <div className={classes.cardDetails}>
                    <CardContent>
                        <Typography component="h2" variant="h5">
                            {demandGeneric.getTitle()}
                        </Typography>
                        {demandGeneric.getType() === Type.MUSIC ? (
                            <Typography variant="subtitle1" color="textSecondary">
                                {`${demandGeneric.getArtist()} • ${demandGeneric.getYear()}`}
                            </Typography>
                        ) : (
                            <Typography variant="subtitle1" color="textSecondary">
                                {`${demandGeneric.getYear()} • ${demandGeneric.getGenres()}`}
                            </Typography>
                        )}
                        {demandGeneric.getType() !== Type.MUSIC && (
                            <Typography component="h2" variant="h6" className={classes.synopsis}>
                                {"Synopsis"}
                            </Typography>
                        )}
                        <Typography variant="body2" paragraph align="justify">
                            {demandGeneric.getSynopsis()}
                        </Typography>
                        <div className={classes.statusContainer}>
                            <TextField
                                className={classes.authorInput}
                                id="author-input"
                                label="Auteur"
                                defaultValue={demand.userAuth.username}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            {demandGeneric.getType() !== Type.MUSIC && (
                                <TextField
                                    className={classes.readOnlyInput}
                                    id="language-input"
                                    label="Langue"
                                    defaultValue={
                                        demandGeneric.getLanguage() === "VF" ? "Version française" : "Version Originale"
                                    }
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            )}
                            {demandGeneric.getType() === Type.TV_SHOW && (
                                <TextField
                                    className={classes.readOnlyInput}
                                    id="season-input"
                                    label="Saison"
                                    defaultValue={`Saison ${demandGeneric.getSeason()}`}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            )}
                            <FormControl variant="outlined" className={classes.status}>
                                <InputLabel id="status-select-label">Statut</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select"
                                    value={demandStatus}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                        handleStatusChange(event.target.value as string)
                                    }
                                    label="Statut"
                                >
                                    {Object.entries(Status).map(([key, value], i) => (
                                        <MenuItem key={i} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </CardContent>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={isToastOpen}
                    autoHideDuration={3000}
                    onClose={() => setToastOpen(false)}
                    message={toastMessage}
                />
            </Card>
        </Fade>
    );
}
