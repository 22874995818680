import React, { useCallback, useEffect, useState } from "react";

import {
    Button,
    Card,
    CardContent,
    CardMedia,
    FormControl,
    Grow,
    Hidden,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@material-ui/core";

import Loader from "../components/loader";

import UserService from "../services/user-service";
import MusicService from "../services/music-service";

import { User, DeezerItem } from "../types";

const useStyles = makeStyles(() => ({
    card: {
        display: "flex",
        marginBottom: "3vh",
        height: "40em"
    },
    cardMedia: {
        width: "40em"
    },
    cardDetails: {
        flex: 1
    },
    cardSubTitle: {
        marginTop: "0.5em"
    },
    trackList: {
        height: "28em",
        overflowY: "auto",
        "scrollbar-width": "thin", // Firefox
        // Chrome
        "&::-webkit-scrollbar": {
            width: "7px",
            background: "#F1F1F1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#CDCDCD"
        }
    },
    paper: {
        display: "flex",
        alignItems: "center",
        padding: "1em"
    },
    formControl: {
        marginRight: "1em"
    },
    buttonRight: {
        marginLeft: "auto"
    }
}));

const getIdFromUrl = () => {
    const url = window.location.href;
    return url.substring(url.lastIndexOf("/") + 1);
};

export default function DemandCreateDeezer({ createDemand }: any) {
    const classes = useStyles();

    const [deezerItem, setDeezerItem] = useState<DeezerItem>(new DeezerItem());
    const [userId, setUserId] = useState(0);
    const [userList, setUserList] = useState<User[]>([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        MusicService.getAlbumDetails(getIdFromUrl())
            .then(res => res.json())
            .then(json => setDeezerItem(json));

        UserService.getAllUsers()
            .then(res => res.json())
            .then(json => {
                const defaultUser = json.find((user: User) => user.username === "etienne582");
                setUserId(defaultUser.id);
                setUserList(json);
            });
    }, []);

    const handleCreateDemand = useCallback(() => {
        setButtonDisabled(true);
        const user = userList.find((user: User) => user.id === userId);
        const newDemandMusic = {
            deezerId: deezerItem.id,
            artist: deezerItem.artist.name,
            title: deezerItem.title,
            poster: deezerItem.cover_xl,
            year: deezerItem.release_date.substring(0, 4)
        };
        const newDemand = {
            demandMusic: newDemandMusic,
            userDest: user
        };
        createDemand(newDemand);
    }, [deezerItem, userList, userId, createDemand]);

    return deezerItem.title === "" ? (
        <Loader />
    ) : (
        <Grow in={true}>
            <div>
                <Card className={classes.card}>
                    <Hidden xsDown>
                        <CardMedia className={classes.cardMedia} image={deezerItem.cover_xl} />
                    </Hidden>
                    <div className={classes.cardDetails}>
                        <CardContent>
                            <Typography component="h1" variant="h4">
                                {deezerItem.title}
                            </Typography>
                            <Typography component="h2" variant="h6">
                                {deezerItem.artist.name} • {deezerItem.release_date.substring(0, 4)}
                            </Typography>
                            <Typography component="h2" variant="h6" className={classes.cardSubTitle}>
                                Tracklist
                            </Typography>
                            <div className={classes.trackList}>
                                <ol>
                                    {deezerItem.tracks.data.map((track, i) => (
                                        <li key={i}>{track.title}</li>
                                    ))}
                                </ol>
                            </div>
                        </CardContent>
                    </div>
                </Card>
                <Paper className={classes.paper}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="dest-select-label">Destinataire</InputLabel>
                        <Select
                            labelId="dest-select-label"
                            id="dest-select"
                            value={userId}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                setUserId(event.target.value as number)
                            }
                            label="Destinataire"
                        >
                            {userList.map((user: User) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user.username}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className={classes.buttonRight}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleCreateDemand}
                            disabled={isButtonDisabled}
                        >
                            Valider
                        </Button>
                    </div>
                </Paper>
            </div>
        </Grow>
    );
}
